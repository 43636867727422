<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import {
    // getExamEasy,
    getNowExam
  } from "@/api/admin/exam/examRecord.js"
  import changeExam from '@/components/change-exam.vue'
  import {
    reportProfessionalList,
    deleteProfessional
  } from "@/api/admin/exam/checkPower";
  import reportProfessionalEdit from "./report-view/report-professional-edit";
  import reportProfessionalAdd from "./report-view/report-professional-add";
  import YzSearch from "@/components/form/yzSearch.vue";
  /**
   * 黑名单
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      reportProfessionalEdit,
      YzSearch,
      reportProfessionalAdd,
      changeExam
    },
    data() {
      return {
        title: "考试职位库/",
        title2: "2021年二级造价工程师职业资格增报专业考试职位库",
        importTitle: "考试职位库导入",
        exportTitle: "考试职位库导出",
        subTitle: "切换报名",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "职位库",
            active: true
          }
        ],
        tableList: [],
        exam: [],
        module: "ZWK",
        fileId: "",
        excelFields: [],
        years: [],
        addForm: {},
        showmodal: false,
        ksbmbh: "",
        ksnf: "",
        ksmc: "",
        select: {},
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          keyword: "",
          ksnf: "",
          keyParam: "zwmc"
        },
        uploadLimit: {},
        search: [],
      };
    },
    methods: {
      searchClick() {
        this.pageData.pageNum = 1
        this.getList()
      },
      getList() {
        this.pageData.uid = this.$route.query['uid']
        reportProfessionalList({
          ...this.pageData,
          ksbmbh: this.ksbmbh
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
            this.pageData.pageNum = res.pageNum
            this.pageData.pageSize = res.pageSize
          }
        })
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      getksmc() {
        let val = this.select.ksbmbh
        let obj = this.exam.find(k => {
          return k.ksbmbh === val
        })
        this.ksmc = obj.ksmc

      },
      // 成绩库上传
      success() {
        this.getList()
      },
      uploadFile() {
        var _this = this;
        _this.$importModal().show({
          title: this.importTitle,
          module: this.module,
          ksbmbh: this.ksbmbh,
          success: this.success
        })
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        this.addForm.ksbmbh = this.ksbmbh
        // this.dialogTitle = "修改考试类别"
      },

      deleteItem(data) {
        this.$confirm(`此操作将永久删除该【${data.zyms}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteProfessional(data.sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //获取切换的考试
      getLastExam(params) {
        getNowExam().then(res => {
          if (res.status) {
            if (params == '') {
              this.ksbmbh = res.data.ksbmbh
              this.ksmc = res.data.ksmc
              this.pageData.ksnf = res.data.ksnf
            } else {
              this.ksbmbh = params
            }
            this.getList({
              ksbmbh: this.ksbmbh
            })
          }
        })
      },
      //申请职位查看
      reportView() {
        window.location.href = "/admin/"
      }
    },
    mounted() {
      this.search = [{
        title: "职位名称",
        bound: "keyword",
        type: "input",
      }, ];
      this.ksnf = new Date().getFullYear();
      this.getYear()
      this.ksbmbh = this.$route.query.ksbmbh ? this.$route.query.ksbmbh : ''
      this.ksmc = this.$route.query.ksmc ? this.$route.query.ksmc : ''
      this.getLastExam(this.ksbmbh)
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :subTitle="subTitle" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <yz-search :data="search" v-model="pageData" @seachList="searchClick"></yz-search>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 " @click="$refs.padd.show({})">
                  添加职位</el-button>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;">序号</th>
                    <th style="width: 8%;">主管部门</th>
                    <th style="width: 5%;">被审核单位号</th>
                    <th style="width: 10%;">被审核单位</th>
                    <th style="width: 3%;">职位号</th>
                    <th style="width: 8%;">职位名称</th>
                    <th style="width: 8%;">缴费金额</th>
                    <th style="width: 8%;">报考比例</th>
                    <th style="width: 8%;">名额</th>
                    <th style="width: 10%;">修改人</th>
                    <th style="width: 3%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td> {{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{$route.query.dwmc}} </td>
                    <td>{{$route.query.bshdwdm}} </td>
                    <td>{{$route.query.bshdwmc}} </td>
                    <td>{{obj.zydm}}</td>
                    <td>{{obj.zymc}}</td>
                    <td>{{obj.jfje}}</td>
                    <td>{{obj.bkbl}}</td>
                    <td>{{obj.me}}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td class="tab-icon">
                      <i class="iconfont icon-edit-two " @click="$refs.pedit.show(obj)"></i>
                      <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <changeExam></changeExam>
    <report-professional-edit ref="pedit" @success="getList"></report-professional-edit>
    <report-professional-add ref="padd" @success="getList"></report-professional-add>
  </Layout>
</template>
